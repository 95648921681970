@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Open+Sans:wght@300;400;700&display=swap');

@import './abstract/variables';

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

// MUI Overrides
.MuiAlert-action {
  align-items: flex-start !important;
}

.MuiTooltip-popper {
  z-index: 1250 !important;
}

.MuiInputBase-root {
  background-color: white;
}

.MuiPickersMonth-root.MuiTypography-subtitle1 {
  color: $color-primary-light !important;
}

.MuiPickersYear-root.MuiTypography-subtitle1 {
  color: $color-primary-light !important;
}

.MuiPickersYear-root.MuiPickersYear-yearDisabled {
  color: #bdbdbd !important;
}

.MuiPickersYear-root.MuiPickersYear-yearSelected {
  color: $color-primary !important;
}

// Openpay generated iframes
iframe#_op_data_r,
iframe#_op_data_antifraud {
  display: none;
}

// Global styles
.page-wrapper {
  padding-top: $appbar-height;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.dimmed-text {
  color: #aaaaaa;
}

.error-text {
  color: $color-error;
}

.text-dark {
  color: $color-primary-dark;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divider {
  &__white {
    background-color: white !important;
  }
}

.icon-list {
  list-style: none;

  &--item {
    display: flex;
    align-items: flex-start;

    &__icon {
      // margin-left: -55px;
      margin-right: 5px;
    }
  }
}

hr {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

small {
  font-family: 'Open Sans', sans-serif;
  color: #aaaaaa;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

@media print {
  .no-print {
    display: none !important;
  }

  @page {
    // 8-1/4 x 11-3/4
    size: A4;
  }
}
