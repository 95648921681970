$color-primary: #61aedd;
$color-secondary: #a08cbd;
$color-tertiary: #50c1c4;
$color-error: #f44336;

$color-primary-light: lighten($color-primary, 20%);
$color-secondary-light: lighten($color-secondary, 20%);
$color-tertiary-light: lighten($color-tertiary, 20%);

$color-primary-dark: darken($color-primary, 20%);
$color-secondary-dark: darken($color-secondary, 20%);
$color-tertiary-dark: darken($color-tertiary, 20%);

$appbar-height: 65px;
